import throttle from "lodash/throttle"

export default function initScrollspy() {
  const section = document.querySelectorAll(".js-step")
  const sections = {}
  if (section.length === 0) return;
  const firstItem = section[0].offsetTop

  // save sections and offsets
  Array.from(section)
    .reverse()
    .forEach(
      (e) => (sections[e.id] = Math.round(e.getBoundingClientRect().top) - 10)
    )

  window.onscroll = throttle(function () {
    var scrollPosition =
      document.documentElement.scrollTop || document.body.scrollTop
    const active = document.querySelector(".scrollspy-active")

    // if before first step
    if (scrollPosition < firstItem && active) {
      active.classList.remove("scrollspy-active")
      return
    }

    // if at least first step
    for (let i in sections) {
      if (sections[i] <= scrollPosition) {
        if (document.querySelector("a[href*=" + i + "].scrollspy-active"))
          return // already active

        // needs update
        if (active) active.classList.remove("scrollspy-active")
        const link = document.querySelector("a[href*=" + i + "]");
        if (link) link.classList.add("scrollspy-active");
        return
      }
    }
  }, 500)
}
