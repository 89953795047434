import * as React from "react"
import { Link } from "gatsby"

export default function Breadcrumb({article}) {
  return (
    <p className="breadcrumb">
      <Link to="/">Accueil</Link>
      <span className="mx-2">{'>'}</span>
      <Link to="/blog">Blog</Link>
      <span className="mx-2">{'>'}</span>
      <span className="breadcrumb__current" aria-current="page">{article}</span>
    </p>
  )
}
