import React from "react"
import GithubIcon from "../icons/github"

export default function GithubWidget({ repo }) {
  return (
    <div className="post-aside__widget github-widget">
      <h4>Code source</h4>
      <div className="github-widget__card">
        <div className="row no-gutters">
          <div className="col github-widget__icon-container">
            <div className="github-widget__icon">
              <GithubIcon />
            </div>
          </div>
          <div className="col">
            <a
              href={repo.url}
              target="_blank"
              rel="noreferrer"
              className="font-weight-bold">
              {repo.name}
            </a>
            <a
              href={repo.owner.url}
              target="_blank"
              rel="noreferrer"
              className="github-widget__owner">
              @{repo.owner.login}
            </a>
          </div>
        </div>
        <p className="github-widget__description">{repo.description}</p>
      </div>
    </div>
  )
}
