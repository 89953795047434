import * as React from "react"
import { Link } from "gatsby"

export default function TagWidget({ tag }) {
  return (
    <div className="tag-widget bg-light p-3 mb-3">
      <p className="mb-0">
        Ce post fait partie de la catégorie <Link to={`/blog?tag=${tag.slug}`}>#{tag.name}</Link>.{' '}
        {tag.description}
      </p>
    </div>
  )
}
