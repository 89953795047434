import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import highlightCode from '../utils/highlightCode';
import initScrollspy from '../utils/initScrollspy';

import Layout from "../components/layout"
import ShareWidget from "../components/blog-post/shareWidget"
import GithubWidget from "../components/blog-post/githubWidget"
import PostImage from "../components/blog-post/postImage"
import TocWidget from "../components/blog-post/tocWidget"
import TagWidget from "../components/blog-post/tagWidget"
import SEO from "../components/seo"
import Breadcrumb from "../components/breadcrumb"

export default function BlogPost({data, location}) {
  const post = data.allWpPost.nodes[0];
  const repo = data.github ? data.github.repository : null;

  useEffect(() => {
    highlightCode();
    initScrollspy();
  });

  const articleMeta = {
    publishedDate: post.date.toString(),
    modifiedTime: post.modifiedGmt.toString()
  }

  return (
    <Layout classes="blog-post">
      <SEO
        article={articleMeta}
        title={post.title}
        description={post.excerpt}
        image={getSrc(post.featuredImage.node.localFile)}
        type="article"
      />
      <div className="container">
        <article>
          <Breadcrumb article={post.title} />
          <PostImage
            startcolor={post.details.gradient.startcolor}
            endcolor={post.details.gradient.endcolor}
            imageSrc={post.details.banner.localFile?.publicURL}
            title={post.title}
            size="large"
          />
          <header>
            <h1>{post.title}</h1>
            <time dateTime={new Date(post.date).toISOString()}>
              Publié le {new Date(post.date).toLocaleDateString()}
            </time>

            <div className="d-lg-none">
              <ShareWidget url={location.href} />
            </div>
          </header>
          <div className="blog-post__content js-content">
            <aside className="d-lg-block d-none post-aside">
              <div>
                {post.details.steps &&
                  <div className="post-aside__widget toc-widget">
                    <h4>Sommaire</h4>
                    <TocWidget steps={post.details.steps.split('\n').filter(s => s !== '')} />
                  </div>
                }
                <ShareWidget url={location.href} />
                {repo && <GithubWidget repo={repo} />}
              </div>
            </aside>
            <div>
              <TagWidget tag={post.categories.nodes[0]} />
              <div dangerouslySetInnerHTML={{ __html: post.content }} ></div>
            </div>
          </div>
          <div className="d-lg-none">
            {repo && <GithubWidget repo={repo} />}
          </div>
        </article>

        <div className="comments">
          <h3>Une question, une remarque&nbsp;?</h3>
          <p className="mb-0">N'hésite pas à <Link to="/#contact">m'envoyer un message</Link>, je te répondrai avec plaisir&nbsp;!</p>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`query ($slug: String!, $repoExists: Boolean!, $name: String!, $owner: String!) {
  allWpPost(filter: {slug: {eq: $slug}}) {
    nodes {
      title
      content
      date
      modifiedGmt
      excerpt
      categories{
        nodes{
          name
          slug
          description
        }
      }
      details {
        github
        steps
        gradient {
          startcolor
          endcolor
        }
        banner {
          localFile {
            publicURL
          }
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1240
                height: 520
                formats: [JPG]
                layout: FIXED
              )
            }
          }
        }
      }
    }
  }
  github @include(if: $repoExists){
    repository(name: $name, owner: $owner) {
      id
      name
      owner {
        login
        url
      }
      url
      description
    }
  }
}
`
