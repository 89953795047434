import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';
import css from 'highlight.js/lib/languages/css';
import shell from 'highlight.js/lib/languages/shell';
import 'highlight.js/styles/atom-one-dark.css';

export default function highlightCode() {
  hljs.registerLanguage('css', css);
  hljs.registerLanguage('html', xml);
  hljs.registerLanguage('javascript', javascript);
  hljs.registerLanguage('shell', shell);

  Array.from(document.querySelectorAll('.js-content pre code')).forEach(el => {
    hljs.highlightElement(el);
  })
}