import * as React from "react"

import TwitterIcon from "../icons/twitter"
import FacebookIcon from "../icons/facebook"
import LinkedinIcon from "../icons/linkedin"

export default function ShareWidget({ url }) {
  return (
    <div className="post-aside__widget share-widget">
      <h4>Partager l'article</h4>

      <a
        className="share-widget__button share-widget__button--twitter"
        href={`https://twitter.com/intent/tweet?url=${url}`}
        target="_blank"
        rel="noreferrer">
        <TwitterIcon />
        <span className="sr-only">Twitter</span>
      </a>

      <a
        className="share-widget__button share-widget__button--facebook"
        href={`https://www.facebook.com/dialog/share?app_id=423860324761163&display=popup&href=${url}`}
        target="_blank"
        rel="noreferrer">
        <FacebookIcon />
        <span className="sr-only">Facebook</span>
      </a>

      <a
        className="share-widget__button share-widget__button--linkedin"
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
        target="_blank"
        rel="noreferrer">
        <LinkedinIcon />
        <span className="sr-only">Linkedin</span>
      </a>
    </div>
  )
}
