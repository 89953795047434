import * as React from "react"
import smoothScroll from '../../utils/smoothScroll';

export default function TocWidget({ steps }) {
  return (
    <div>
      {steps.map((step, index) => (
        <a
          onClick={smoothScroll}
          href={"#step-" + (index + 1)}
          key={index}
          className="widget-toc__item">
          <span>{index + 1}</span> {step}
        </a>
      ))}
    </div>
  )
}
